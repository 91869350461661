import React from 'react';
import {Box, Checkbox, MenuItem, Select, Tooltip, useTheme} from '@mui/material';
import {LoadingButton} from '@mui/lab';
import {Cancel, Check, Mail} from '@mui/icons-material';
import {useGridApiContext} from '@mui/x-data-grid-pro';
import {useMutation, useQueryClient} from 'react-query';
import userAPI from '../apis/userAPI';
import useAlert from '../hooks/alert/useAlert';
import useSession from '../hooks/user/useSession';

export const Actions = ({userId, userState, isActive, handleApiError}) => {
  const alert = useAlert();
  const {userInfo} = useSession();
  const queryClient = useQueryClient();

  const deactivateUser = useMutation((userId) => userAPI.deactivate(userId), {
    onSuccess: () => {
      alert.success('Successfully deactivated user');
      queryClient.invalidateQueries(['usersForOrg', userInfo.orgId]);
    },
    onError: (err) => handleApiError('Could not deactivate user', err),
    retry: 1
  });

  const activateUser = useMutation((userId) => userAPI.activate(userId), {
    onSuccess: () => {
      alert.success('Successfully re-activated user');
      queryClient.invalidateQueries(['usersForOrg', userInfo.orgId]);
    },
    onError: (err) => handleApiError('Could not re-activate user', err),
    retry: 1
  });

  const resetPassword = useMutation((userId) => userAPI.resetPassword(userId), {
    onSuccess: () => {
      alert.success('Re-sent temporary password to user');
    },
    onError: (err) => handleApiError('Could not reset password', err),
    retry: 1
  });

  const {palette} = useTheme();
  const btnStyle = {minWidth: '48px', '.MuiLoadingButton-loadingIndicator': {color: palette.primary.main}};

  const userToChange = userState.find(user => user.user_id === userId);

  return (
    <Box
      key={userId}
      sx={{width: '100%', display: 'flex'}}
    >
      {isActive ?
        <Tooltip title='Deactivate user'>
          {/* span is needed to display tooltip for a disabled button */}
          <span>
            <LoadingButton
              onClick={() => deactivateUser.mutate(userId)}
              loading={deactivateUser.isLoading}
              disabled={userToChange.cognito_username === userInfo.username}
              sx={btnStyle}
            >
              <Cancel/>
            </LoadingButton>
          </span>
        </Tooltip>
       :
        <Tooltip title='Activate user'>
          <LoadingButton
            onClick={() => activateUser.mutate(userId)}
            loading={activateUser.isLoading}
            sx={btnStyle}
          >
            <Check/>
          </LoadingButton>
        </Tooltip>
      }

      <Tooltip title='Reset Password'>
        <span>
          <LoadingButton
            onClick={() => resetPassword.mutate(userId)}
            loading={resetPassword.isLoading}
            disabled={userToChange.cognito_username === userInfo.username}
            sx={btnStyle}
          >
          <Mail />
        </LoadingButton>
        </span>
      </Tooltip>
    </Box>
  );
};

export const OrgSelect = ({userId, userState, setUserState, handleRowChange}) => {
  const {userInfo} = useSession();
  const queryClient = useQueryClient();
  const apiRef = useGridApiContext();

  const userToChange = userState.find(user => user.user_id === userId);

  const orgs = queryClient.getQueryData('organizations');

  return (
    <Select
      fullWidth
      value={userToChange.org_id}
      onChange={(event) => {
        const selectedOrg = orgs?.find(org => org.id === event.target.value);

        userToChange.org_id = selectedOrg.id;

        setUserState(userState);
        apiRef.current.updateRows(userState);
        handleRowChange(userId);
      }}
      disabled={userToChange.cognito_username === userInfo.username}
    >
      {orgs
        ?.map(org => (
          <MenuItem key={org.id} value={org.id}>
            <b>{`${org.org_type_abbrev === 'FSP' ? 'FSA' : org.org_type_abbrev} ${org.code}`}</b>
            &nbsp;
            {`${org.name}`}
          </MenuItem>
      ))}
    </Select>
  );
};

export const AdminCheckbox = ({userId, userState, setUserState, handleRowChange}) => {
  const {userInfo} = useSession();
  const apiRef = useGridApiContext();

  const userToChange = userState.find(user => user.user_id === userId);

  return (
    <Checkbox
      checked={Boolean(userToChange.is_admin)}
      onChange={(event) => {
        userToChange.is_admin = Number(event.target.checked);
        setUserState(userState);
        apiRef.current.updateRows(userState);
        handleRowChange(userId);
      }}
      disabled={userToChange.cognito_username === userInfo.username}
    />
  );
};
