import React, {FC, useEffect, useState} from "react";
import {
  Button,
  Checkbox,
  CircularProgress,
  Dialog, FormControlLabel, IconButton,
  MenuItem,
  Select,
  Stack,
  Typography
} from "@mui/material";
import {emailRegex} from "../util";
import ChipMultiInput from "../components/ChipMultiInput";
import userAPI from "../apis/userAPI";
import {useMutation, useQuery, useQueryClient} from "react-query";
import useSession from "../hooks/user/useSession";
import orgAPI from "../apis/orgAPI";
import {useResponsive} from "../hooks/style";
import CloseIcon from "@mui/icons-material/Close";

type CreateUserDialogProps = {
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
  onError: (msg: string) => void;
  orgOptions: any
};

const CreateUserDialog: FC<CreateUserDialogProps> = ({open, onClose, onSuccess, onError, orgOptions}) => {
  const {userInfo: {orgId: userOrgId, orgTypeAbbrev}} = useSession();
  const {isDesktop, isPhone} = useResponsive();
  const queryClient = useQueryClient();

  const [emails, setEmails] = useState<string[]>([]);
  const [isAdmin, setAdmin] = useState<boolean>(false);
  const [addOrgId, setAddOrgId] = useState<number>(0);

  useEffect(
    () => {
      setAddOrgId(userOrgId);
      queryClient.invalidateQueries('organizations');
    },
    [userOrgId]
  );

  // const orgOptions = useQuery<any>(
  //   ['organizations'],
  //   async () => {
  //     const orgs = await orgAPI.getAll() as {id: number, name: string}[];
  //     if (orgTypeAbbrev === 'SU')
  //       return orgs;
  //     else
  //       return orgs.filter(org => org.id === userOrgId);
  //   },
  //   {
  //     staleTime: Infinity
  //   }
  // );

  const addEmails = useMutation(() => userAPI.addAll(emails, addOrgId, isAdmin), {
    onSuccess: onSuccess,
    onError: () => onError('Failed to create accounts.  Please contact iYOTAH support.')
  });

  const resetValues = () => {
    setEmails([]);
    setAdmin(false);
  };

  const handleOrgChange = (org: number | string) => {
    if (org === '') {
      setAddOrgId(0);
    }
    else setAddOrgId(org as number);
  };

  const handleAdd = (email: string) => {
      setEmails([...emails, email]);
  };

  const handleRemove = (email: string) => {
    setEmails(emails.filter((entry => entry !== email)));
  };

  const handleClose = () => {
    resetValues();
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullScreen={isPhone}
      sx={isPhone ? {} : {p: '1rem'}}
      PaperProps={{sx: isPhone ? {} : {p: '1rem'}}}
    >
      <Stack direction='column' alignItems='left' gap={1} sx={isPhone ? {p: 2} : {width: 400}}>
        <Stack direction='row' sx={{justifyContent: 'space-between', alignItems: 'center'}}>
          <Typography variant='h5' >Invite Users</Typography>
          {
            isPhone &&
            <IconButton onClick={handleClose}>
              <CloseIcon/>
            </IconButton>
          }
        </Stack>
        <Stack direction='row' width='inherit' gap={1}>
          {orgOptions.isSuccess && (
            <Select
              value={addOrgId || ''}
              onChange={(event) => handleOrgChange(event.target.value)}
            >
              {orgOptions.data.map(org => (
                <MenuItem
                  key={org.id}
                  value={org.id}
                >
                  {org.name}
                </MenuItem>
              ))}
            </Select>
          )}
          <FormControlLabel
            control={<Checkbox
              checked={isAdmin}
              onChange={(event) => setAdmin(event.target.checked)}/>}
            label='Admin'
          />
        </Stack>
        <ChipMultiInput
          values={emails}
          onAdd={handleAdd}
          onRemove={handleRemove}
          inputRegex={emailRegex}
          invalidFormatMessage='Input must be a valid email address'
          containerSx={{
              width: 'inherit'
          }}
        />
        <Stack direction='row' justifyContent='flex-end' gap={1} width='inherit'>
          <Button variant='contained' color='secondary' onClick={handleClose}>
            Cancel
          </Button>
          <Button variant='contained' color='primary' onClick={() => addEmails.mutate()} disabled={!emails.length}>
            {addEmails.isLoading ? (<CircularProgress />): "Invite" }
          </Button>
        </Stack>
      </Stack>
    </Dialog>
  );
};

export default CreateUserDialog;
