import React, {useEffect, useState} from 'react';
import {useMutation, useQuery, useQueryClient} from 'react-query';
import {Box, Button, LinearProgress, useMediaQuery, useTheme} from '@mui/material';
import {LoadingButton} from '@mui/lab';
import {DataGridPro} from '@mui/x-data-grid-pro';
import {getSuColumns} from './suColumns';
import {getAdminColumns} from './adminColumns';
import CreateUserDialog from './CreateUserDialog';
import personAPI from '../apis/personAPI';
import useSession from '../hooks/user/useSession';
import userAPI from '../apis/userAPI';
import useAlert from '../hooks/alert/useAlert';
import {jsonDiff} from '../util';
import orgAPI from '../apis/orgAPI';

const UserViewPage = () => {
  const alert = useAlert();
  const queryClient = useQueryClient();
  const {userInfo} = useSession();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const [userProps, setUserProps] = useState([]);
  const [userDiffs, setUserDiffs] = useState({});
  const [selectionModel, setSelectionModel] = useState([]);

  const getUsersForOrganization = useQuery(
    ['usersForOrg', userInfo.orgId],
    personAPI.get,
    {
      enabled: userInfo.orgId !== 0,
      select: (response) => response.data,
      staleTime: Infinity
    }
  );

  const getOrgs_UserView = useQuery(
    'organizations',
      async () => {
        const orgs = await orgAPI.getAll();
        if (userInfo.orgTypeAbbrev === 'SU')
          return orgs;
        else
          return orgs.filter(org => org.id === userInfo.orgId);
      },
      {
        staleTime: Infinity
      }
  );

  // const orgsData = queryClient.getQueryData('organizations');
  // const orgsState = queryClient.getQueryState('organizations');
  // console.log(orgsState);
  // console.log('Orgs fetching...', queryClient.isFetching('organizations'));

  useEffect(() => {
    if (getUsersForOrganization.data?.length) {
      setUserProps(JSON.parse(JSON.stringify(getUsersForOrganization.data)));
      setUserDiffs({});
      setSelectionModel([]);
    }
  }, [getUsersForOrganization.data]);

  const handleCreateSuccess = () => {
    setCreateDialogOpen(false);
    alert.success('Successfully created user accounts');
    queryClient.invalidateQueries(['usersForOrg', userInfo.orgId]);
  };

  const handleCreateUsersError = (msg) => {
    setCreateDialogOpen(false);
    alert.error(msg);
  };

  const handleApiError = (err, message) => {
    if (err.response)
      alert.error(`${message}: ${err.response.data.message}`);
    else
      alert.error(`${message}: ${err.message}`);
  };

  const handleRowChange = (userId) => {
    const rawUser = getUsersForOrganization.data.find(user => user.user_id === userId);
    const stateUser = userProps.find(user => user.user_id === userId);
    const diff = jsonDiff(rawUser, stateUser);

    if (Object.keys(diff).length)
      userDiffs[userId] = diff;
    else if (userDiffs[userId])
      delete userDiffs[userId];

    setUserDiffs(userDiffs);
    setSelectionModel(Object.keys(userDiffs));
  };

  const updateUser = useMutation(async () => {
    const body = Object.entries(userDiffs).map(([userId, changes]) => {
      const stateUser = userProps.find(user => user.user_id === Number(userId));

      return {
        id: Number(userId),
        organization_id: changes.org_id ?? stateUser.org_id,
        is_admin: changes.is_admin ?? stateUser.is_admin
      };
    });

    await userAPI.update(body);
  }, {
    onSuccess: () => {
      alert.success('Successfully updated user(s)');
      queryClient.invalidateQueries(['usersForOrg', userInfo.orgId]);
    },
    onError: (err) => handleApiError('Could not update user(s)', err),
    retry: 1
  });

  const columns = userInfo.orgTypeAbbrev === 'SU' ?
    getSuColumns(userInfo.username, userProps, setUserProps, handleRowChange, handleApiError, isMobile) :
    getAdminColumns(userInfo.username, userProps, setUserProps, handleRowChange, handleApiError);

  return (
    <Box sx={{px: {xs: 1, md: 0}}}>
      <CreateUserDialog
        open={createDialogOpen}
        onClose={() => setCreateDialogOpen(false)}
        onSuccess={handleCreateSuccess}
        onError={handleCreateUsersError}
        orgOptions={getOrgs_UserView}
      />

      <Box
        sx={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end', my: '1rem'}}
      >
        <Button
          color='primary'
          variant='contained'
          size='large'
          onClick={() => setCreateDialogOpen(true)}
        >
          Invite
        </Button>
      </Box>

      <Box sx={{height: isMobile ? 'calc(100vh - 285px)' : 'calc(100vh - 335px)'}}>
        <DataGridPro
          sx={(theme) => ({
            '& .MuiDataGrid-columnHeaders': {
              backgroundColor: theme.palette.secondary.main,
            },
            '& .MuiDataGrid-pinnedColumnHeaders': {
              backgroundColor: theme.palette.secondary.main
            },
            '& .active-user': {
              color: theme.palette.primary.main
            },
            '& .inactive-user': {
              color: theme.palette.secondary.dark
            }
          })}
          components={{
            LoadingOverlay: LinearProgress,
          }}
          columns={columns}
          getRowId={(row) => row.user_id}
          pinnedColumns={{left: ['actions']}}
          rows={userProps && getOrgs_UserView.data ? userProps : []}
          loading={getUsersForOrganization.isFetching || getOrgs_UserView.isFetching}
          disableSelectionOnClick
          headerHeight={35}
          selectionModel={selectionModel}
        />
      </Box>

      <Box
        sx={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end', my: 2}}
      >
        <LoadingButton
          color='primary'
          variant='contained'
          size='large'
          disabled={!Object.keys(userDiffs).length}
          onClick={() => updateUser.mutate()}
          loading={updateUser.isLoading}
        >
          Save
        </LoadingButton>
      </Box>
    </Box>
  );
};

export default UserViewPage;
