import US_STATES from "../lib/US_STATES";

export function getDataGridLicenseKey() {
  return '61a1e8837c63b6e22e5027bdc6c4e9f0Tz01NjQ3MCxFPTE3MDMxODQ1MDg2ODYsUz1wcm8sTE09cGVycGV0dWFsLEtWPTI=';
}

export function isObject(x) {
  return x != null && typeof x === 'object';
}

export function isNumber(x) {
  return !isNaN(x);
}

export function isFSPId(x) {
  if (x.length >= 2 && isNumber(x)) {
    return true;
  }
  return false;
}

export const isStateCode = (x) => {
  return !!US_STATES.includes(x);
};

export function isString(x) {
  return Object.prototype.toString.call(x) === '[object String]';
}

/**
 * Wraps the {@link hasParams} method to get a param's value from the Amplify config.
 * @param param {string|string[]} Param name or an array of param names
 * @returns {*}
 */
export function fetchConfig(param) {
  let configValue;
  const fetchParam = hasParams(param);

  if (fetchParam) {
    configValue = fetchParam[param];
  } else {
    throw new Error(`${param} config value is not valid!`);
  }
  return configValue;
}

export const emailRegex = new RegExp(
  /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
);

/**
 * Checks the Amplify config if params are there, and returns them if so. Returns false if not.
 * @param paramArray {string|string[]} Param name or an array of param names
 * @param missingParamArray {string[]} An array that will hold params missing from the config
 * @returns {{}|boolean}
 */
export function hasParams(paramArray, missingParamArray) {
  const paramObj = {};
  let paramList;

  if (isString(paramArray)) {
    paramList = [paramArray];
  } else {
    paramList = [...paramArray];
  }

  let hasValues = true;

  for (const param of paramList) {
    const paramValue = window.amplifyConfig?.[param];
    if (!window.amplifyConfig || !paramValue) {
      if (missingParamArray) missingParamArray.push(param);
      hasValues = false;
    } else {
      paramObj[param] = paramValue;
    }
  }
  if (hasValues) {
    return paramObj;
  }
  return false;
}

export function jsonDiff(obj1, obj2) {
  var ret = {};
  for(var i in obj2) {
    if(!obj1.hasOwnProperty(i) || obj2[i] !== obj1[i]) {
      if(!Array.isArray(obj2[i]) || !(JSON.stringify(obj2[i]) == JSON.stringify(obj1[i]))){
        ret[i] = obj2[i];
      }
    }
  }
  return ret;
}
