import {Actions, AdminCheckbox} from './userComponents';

export const getAdminColumns = (sessionUsername, userState, setUserState, handleRowChange, handleApiError) => {
  return [
    {
      field: "actions",
      headerName: "Actions",
      headerClassName: "header",
      minWidth: 120,
      renderCell: params =>
        <Actions
          userId={params.row.user_id}
          userState={userState}
          isActive={params.row.active}
          handleApiError={handleApiError}
        />
    },
    {
      field: 'cognito_username',
      headerName: 'Username',
      headerClassName: 'header',
      minWidth: 200,
      valueFormatter: params => params.value === sessionUsername && `${params.value} (you)`
    },
    {
      field: 'active',
      headerName: 'Status',
      headerClassName: 'header',
      valueFormatter: (params) => params.value ? 'Active' : 'Inactive',
      cellClassName: (params) => params.value ? 'active-user' : 'inactive-user',
      minWidth: 65
    },
    // TODO add account_created after updating person API with field
    {
      field: 'is_admin',
      headerName: 'Admin',
      headerClassName: 'header',
      minWidth: 110,
      renderCell: params => (
        <AdminCheckbox
          userId={params.row.user_id}
          userState={userState}
          setUserState={setUserState}
          handleRowChange={handleRowChange}
        />
      )
    }
  ];
};